.pricing-page {
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    overflow: hidden;
  }
  
  .pricing-heading {
    background: url('../Images/PricingImg.png') no-repeat center center/cover;
    padding: 80px 0;
    color: white;
    text-align: center;
  }
  
  .pricing-heading h2 {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .pricing-content {
    padding: 40px 20px;
  }
  
  .pricing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    text-align: center;
  }
  
  .pricing-card {
    background-color: white;
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    animation: fadeInUp 0.8s ease-in-out;
  }
  
  .pricing-card:hover {
    transform: translateY(-10px);
  }
  
  .pricing-icon {
    font-size: 4rem;
    color: #ff6600;
    margin-bottom: 20px;
  }
  
  .pricing-card h3 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .pricing-card p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  .price {
    font-size: 1.5rem;
    color: #ff6600;
    font-weight: bold;
    margin-top: 20px;
  }
  

  @media screen and (max-width:320px) {
    .pricing-card{
        width: 280px;
    }
  }