/* Services Page Styles */
.services-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f4f4f4;
    padding-bottom: 200px;
    overflow: hidden;
  }
  
  /* Page Heading */
  .services-heading {
    background: url('../Images/SB.png') no-repeat center center/cover;
    text-align: center;
    padding: 100px 20px;
    color: white;
    animation: fadeInDown 1.2s ease-in-out;
  }
  
  .services-heading h1 {
    font-size: 3.5rem;
    margin: 0;
  }
  
  /* Service Section */
  .service-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    gap: 30px;
    text-align: left;
    background-color: white;
    border-bottom: 2px solid #ddd;
  }
  
  .service-section:nth-of-type(even) {
    background-color: #f9f9f9;
  }
  
  .service-content {
    max-width: 600px;
    padding: 20px;
    animation: fadeInLeft 1.2s ease-in-out;
  }
  
  .service-content h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #ff6600;
  }
  
  .service-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .service-content ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .service-content li {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  .SI1{
    background-color: red;
    width: 100%;
    background-image: url("../Images/SB8.png");
    background-size: cover;
    height: 300px;
  }

  .SI2{
    background-color: red;
    width: 100%;
    background-image: url("../Images/SB9.png");
    background-size: cover;
    background-position: bottom;
    height: 300px;
  }


  
  /* Keyframe Animations */
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  