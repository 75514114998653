.contact-page {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    overflow: hidden;
  }
  
  .contact-heading {
    background: url('../Images/contactImg.png') no-repeat center center/cover;
    padding: 80px 0;
    color: white;
    text-align: center;
  }
  
  .contact-heading h2 {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .contact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin-bottom: 30px;
  }
  
  .contact-input,
  .contact-textarea {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 100%;
  }
  
  .contact-button {
    background-color: #ff6600;
    color: white;
    padding: 15px 20px;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
  }
  
  .contact-info {
    display: flex;
    justify-content: space-around;
    max-width: 800px;
    width: 100%;
  }
  
  .info-item {
    text-align: center;
    margin: 20px;
 
  }

  .info-item p{
    font-size: 0.8rem !important;
  }
  
  .contact-icon {
    font-size: 2.5rem;
    color: #ff6600;
    margin-bottom: 10px;
  }
  
  .info-item p {
    font-size: 1.1rem;
    color: #555;
  }
  

  @media screen and (max-width:768px) {
    .contact-info{
      flex-direction: column;
    }
  }