/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Footer Styles */
  .footer {
    background-color: #f8f9fa;
    background-color:rgba(0, 0, 139, 0.6);
    color: white;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    position: relative;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .footer-section {
    flex: 1;
    margin: 10px;
  }
  
  .footer-section h2, .footer-section h4 {
    color: orange;
  }
  
  .footer-section p {
    margin: 10px 0;
    font-size: 0.9rem;
  }
  
  .footer-section a {
    color:white;
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 0.9rem;
  }
  
  .footer-section a:hover {
    color: orange;
  }
  
  .social-icons {
    display: flex;
    gap: 15px;
  }
  
  .social-icons a {
    color: white;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: orange;
  }
  
  /* Footer Bottom */
  .footer-bottom {
    text-align: center;
    padding: 10px;
    border-top: 1px solid #ddd;
    margin-top: 20px;
  }
  
  /* Animations */
  .footer-section h2, .footer-section h4 {
    animation: fadeIn 1s ease-in-out;
  }
  
  .footer-section p {
    animation: fadeInUp 1s ease-in-out;
  }

  .footer-section img{
    width: 50px;
  }


  .links li{
    cursor: pointer;
  }
  
  /* Keyframes */
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @keyframes fadeInUp {
    0% { transform: translateY(20px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
  }
  
  /* Responsiveness */
  @media screen and (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-section {
      margin: 10px 0;
    }
    .social-icons{
        justify-content: center;
    }
  }
  