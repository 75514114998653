/* Global Styles for About Us Page */
.about-us-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f4f4f4;
    overflow: hidden;
  }
  
  /* Page Heading */
  .about-us-heading {
    background: url('../Images/whoweare.png') no-repeat center center/cover;
    text-align: center;
    padding: 100px 0;
    color: white;
  }
  
  .about-us-heading h1 {
    font-size: 3.5rem;
    animation: fadeInDown 1.2s ease-in-out;
  }
  
  /* Content Section */
  .about-us-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    gap: 30px;
  }
  
  .about-us-text {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 20px;
    animation: fadeInLeft 1.2s ease-in-out;
  }
  
  .about-us-text h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: orange;
  }
  
  .about-us-text p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .about-icons {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .about-icon {
    font-size: 3rem;
    color: #ff6600;
    animation: bounce 1.5s infinite;
  }
  
  /* Image Section */

  
  .about-us-image img {
    width: 100%;
    height: 300px;
    /* max-width: 500px; */
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    animation: fadeInRight 1.2s ease-in-out;
  }
  
  /* Our Vision Section */
  .about-vision-section {
    background-color: white;
    padding: 50px 20px;
    text-align: center;
    animation: fadeInUp 1.2s ease-in-out;
  }

  .about-vision-section h2{
    color: orange;
  }

  .VisionImgWrap{
      width: 100%;
      padding: 30px;
      height: 300px;
      overflow: hidden;
  }

  .VisionImgWrap img{
    width: 100%;
  }
  
  /* .about-vision-section img {
    margin-top: 30px;
    width: 100%;
    max-width: 700px;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  } */
  
  /* Motto and Values Section */
  .about-motto-section {
    background-color: #ff6600;
    color: white;
    padding: 50px 20px;
    text-align: center;
    animation: fadeInUp 1.2s ease-in-out;
  }
  
  .about-values {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }
  
  .value-card {
    background-color: white;
    color: #333;
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    width: 100%;
    max-width: 300px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1.2s ease-in-out;
  }
  
  .value-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .value-icon {
    font-size: 3rem;
    color: #ff6600;
    margin-bottom: 15px;
    animation: pulse 2s infinite;
  }
  
  /* Keyframes for Animations */
  @keyframes fadeInDown {
    from { opacity: 0; transform: translateY(-50px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes fadeInLeft {
    from { opacity: 0; transform: translateX(-50px); }
    to { opacity: 1; transform: translateX(0); }
  }
  
  @keyframes fadeInRight {
    from { opacity: 0; transform: translateX(50px); }
    to { opacity: 1; transform: translateX(0); }
  }
  
  @keyframes fadeInUp {
    from { opacity: 0; transform: translateY(50px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }
  
  @keyframes pulse {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.1); }
  }
  
  @media screen and (max-width:884px) {
    .about-us-text{
      width: 100%;
    }

    
  }

  @media screen and (max-width:428px) {
    .about-us-image img{
      height: 200px;
    }
  }