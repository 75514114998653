/* Styles for Services Brief Section */
.services-brief-section {
    background-color: #f4f4f4;
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    text-align: left;
    background-image: url("../Images/SB9.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  /* Content Section */
  .services-brief-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* max-width: 1200px; */
    flex-wrap: wrap;
    gap: 30px;
  }
  
  /* Left-side Text Section */
  .services-brief-text {
    width: 60%;
    /* max-width: 600px; */
    padding: 20px;
    animation: fadeInLeft 1.2s ease-in-out;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
  }
  
  .services-brief-text h2 {
    font-size: 2rem;
    color: orange;
    margin-bottom: 20px;
  }
  
  .services-brief-text p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
  }
  
  .explore-more-btn {
    padding: 10px 20px;
    font-size: 1.2rem;
    color: white;
    background-color: #ff6600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    animation: fadeInUp 1.2s ease-in-out;
    transition: background-color 0.3s ease;
  }
  
  .explore-more-btn:hover {
    background-color: #e65c00;
  }
  
  .services-brief-image{
    width: 30%;
    height: 300px;
    background-image: url("../Images/SB.png");
    background-size: cover;
    border-radius: 10px;
    background-color: red;
    /* z-index: 999; */
  }
  /* Image Section */
  /* .services-brief-image img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    animation: fadeInRight 1.2s ease-in-out;
  } */
  
  /* Keyframe Animations */
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  

  @media screen and (max-width:884px) {
    .services-brief-image{
        width: 100%;
        height: 300px;
        /* overflow: hidden; */
        /* border-radius: 10px; */
    } 
    .services-brief-text{
      width: 100%;
    }
    .services-brief-image img{
        /* max-width: 2000px; */
    }
  }