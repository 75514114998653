/* Styles for About Us Brief Section */
.about-us-brief-section {
    background-color: #f4f4f4;
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    /* text-align: left; */
  }
  
  /* Text Content Section */
  .about-us-brief-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    flex-wrap: wrap;
    gap: 40px;
  }
  
  /* Left-side Text Section */
  .about-us-brief-text {
    /* max-width: 600px; */
    width: 50%;
    padding: 20px;
    animation: fadeInLeft 1.2s ease-in-out;
  }
  
  .about-us-brief-text h2 {
    font-size: 2rem;
    color: orange;
    margin-bottom: 20px;
  }
  
  .about-us-brief-text p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
  }
  
  .about-brief-icons {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .about-brief-icon {
    font-size: 2.5rem;
    color: #ff6600;
    animation: bounce 1.5s infinite;
  }
  
  /* Button Styling */
  .more-about-btn {
    padding: 10px 20px;
    font-size: 1.2rem;
    color: white;
    background-color: #ff6600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    animation: fadeInUp 1.2s ease-in-out;
    transition: background-color 0.3s ease;
  }
  
  .more-about-btn:hover {
    background-color: #e65c00;
  }

  .about-us-brief-image{
    width: 40%;
    height: 300px;
    background-image: url("../Images/whoweare2.png");
    background-size: cover;
  }
  
  /* Image Section */
  /* .about-us-brief-image img {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    animation: fadeInRight 1.2s ease-in-out;
  } */
  
  /* Keyframe Animations */
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  

  @media screen and (max-width:884px) {
    .about-us-brief-section{
        flex-direction: column;
        justify-content: center;
        /* text-align: center; */
    }

    .about-us-brief-image{
      /* background-color: red; */
      width: 100%;
      height: 300px;
    }

    /* .about-us-brief-image img{
        width:100%;

        max-width: 2000px;
    } */
    .about-us-brief-text h2{
        font-size: 1.5rem;
    }

    .about-us-brief-text{
      width: 100%;
    }
  }