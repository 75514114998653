/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Services Section */
  .services-section {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    background-color: #f9f9f9;
    padding: 50px 20px;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .services-title {
    font-size: 2rem;
    color: orange;
    margin-bottom: 40px;
    /* animation: fadeInUp 1s ease-in-out; */
  }
  
  .services-grid {
    display: flex;
    /* display: grid; */
    /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    /* align-items: center; */
  }
  
  .service-card {
    background-color: white;
    border-radius: 15px;
    /* overflow: hidden; */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    /* animation: fadeInUp 0.8s ease-in-out; */
    width: 300px;
  }
  
  .service-card:hover {
    
    transform: translateY(-10px);
  }
  
  .service-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 15px 15px 0px 0px;
  }
  
  .service-info {
    padding: 20px;
  }
  
  .service-title {
    font-size: 1.2rem;
    color: #ff6600;
    margin-bottom: 15px;
  }
  
  .service-description {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Animations */
  @keyframes fadeInUp {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  

  @media screen and (max-width:670px) {
    .services-title{
      font-size: 1.5rem;
    } 

    .services-section{
      padding: 50px 5px;
    }

    .services-grid{
      width: 100%;
      gap: 8px;
    }
    .service-card{
      width: 48%;
      margin-bottom: 10px;
    }
    .service-image{
      height: 100px;
    }
    .service-info{
      padding: 8px 5px;
    }
    .service-description{
      font-size: 0.8rem;
    }
  }