/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .hero {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Arial', sans-serif;
    overflow: hidden;
    transition: background-image 1s ease-in-out;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  
  .hero-content {
    position: relative;
    z-index: 2;
    text-align: center;
    /* animation: fadeIn 2s ease-in-out; */
  }
  
  .company-name {
    font-size: 3rem;
    margin-bottom: 20px;
    /* animation: slideIn 1s ease-in-out; */
  }
  
  .caption {
    font-size: 1.5rem;
    margin-bottom: 40px;
    /* animation: fadeIn 2s ease-in-out; */
  }
  
  /* Tracking Form */
  .tracking-form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* animation: fadeInUp 2s ease-in-out; */
  }
  
  .tracking-form input {
    padding: 10px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    width: 250px;
  }
  
  .tracking-form button {
    padding: 10px 20px;
    background-color: orange;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .tracking-form button:hover {
    background-color: darkorange;
  }
  
  /* Animations */
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @keyframes slideIn {
    0% { transform: translateY(-20px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
  }
  
  @keyframes fadeInUp {
    0% { transform: translateY(20px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
  }
  
  /* Responsiveness */
  @media screen and (max-width: 768px) {
    .company-name {
      font-size: 2.5rem;
    }
  
    .caption {
      font-size: 1.2rem;
    }
  
    .tracking-form input {
      width: 200px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .company-name {
      font-size: 2rem;
    }
  
    .caption {
      font-size: 1rem;
    }
  
    .tracking-form input {
      width: 180px;
    }

    .hero{
      max-height: 500px;
    }
    .tracking-form{
      flex-direction: column;
    }
  }
  