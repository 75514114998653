/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }


  .ShipmentCalculatorWrap{
    display: flex;
    padding: 50px 10px;
  }
  
  /* Shipment Calculator Styles */
  .shipment-calculator {
    width:50%;
    /* background-color: #f9f9f9; */
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    /* width: 100%; */
    max-width: 600px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
  }

  
  .title {
    text-align: center;
    color:orange;
    margin-bottom: 20px;
  }
  
  .calculator-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .calculator-form label {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
  }
  
  .calculator-form input,
  .calculator-form select {
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .calculate-button {
    padding: 10px;
    background-color: orange;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .calculate-button:hover {
    background-color: darkorange;
  }
  
  .result {
    margin-top: 20px;
    text-align: center;
    animation: fadeIn 1s ease-in-out;
  }
  
  .result h3 {
    color: #333;
  }
  
  .result p {
    font-size: 1.5rem;
    color: orange;
  }
  
  /* Animations */
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  .InputWrap{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

  }

  .ShipmentCalculatorImg{
    width: 50%;
    height: 500px;
    /* background-color: red; */
    background-image: url("../Images/ShipmentCalculatorImg.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .ShipmentCalculatorImg img{
    /* width: 100%; */
  }
  

  @media screen and (max-width:884px) {
    .ShipmentCalculatorWrap{
        flex-direction: column;
    }

    .shipment-calculator{
      width: 100%;

    }

    .ShipmentCalculatorImg{
      width: 100%;
      height: 300px;
      /* overflow: hidden; */
      background-color: red;
    }

    .InputWrap{
        flex-direction: column;
    }
  }