*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.admin-view-shipments-container {
    padding: 20px;
    /* max-width: 1200px; */
    margin: 0 auto;
    width: 100%;
    height: 500px;
    overflow-y: scroll;


  }
  
  .admin-view-shipments-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
    color: orange;
    width: 100%;
    /* background-color: red; */
  }
  
  .admin-view-shipments-content {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    gap: 20px;
    width: 100%;
    /* background-color: red; */
  }
  
  .shipment-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;
  }
  
  .shipment-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    color: gray;
    width: 100%;
    /* flex-wrap: wrap; */
  }
  
  .shipment-card-header h2 {
    margin: 0;
    font-size: 1.2rem;
    color:orangered;
    width: 100%;
  }

  .shipment-card-header span{
    color: orangered;
  }
  
  .shipment-id {
    font-size: 0.9rem;
    color: #666;
  }
  
  .shipment-details {
    display: flex;
    flex-direction: column;
  }


  
  .shipment-detail {
    margin-bottom: 10px;
  }
  
  .shipment-detail strong {
    display: block;
    color:orange;
  }

  .shipment-card button{
    padding: 5px;
    margin-right: 10px;
    border-radius: 5px;
    border: none;
    background-color: orange;
    color: white;
    cursor: pointer;
  }

  .shipment-card button:hover{
    background-color: orangered;
  }
  

  .admin-view-shipments-search {
    margin-bottom: 20px;
  }
  
  .admin-view-shipments-search-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }

  .shipmentInlineDetail{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  @media screen and (max-width:428px) {
    .shipment-card-header{
      display: flex;
      flex-direction: column  ;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      /* background-color: red; */
    }

    .shipment-card button{
      margin-top: 10px;
    }

    .shipment-card {
      padding: 5px;
    }
    .admin-view-shipments-container{
      padding: 20px 0px;
    }
  }